import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../../Particle";
import Typing from "./Typing";
import Tilt from "react-parallax-tilt";
import AboutmeContainer from "../../components/aboutme/aboutmeContainer";
import Fade from "react-reveal/Fade";
import Certification from "../../components/certificate/Certification";
import Testimonial from "../../components/testimonials/Testimonial";
import Projects from "../../components/projects/Projects";
import Contactus from "../../components/contactus/Contactus";
import SocialMedia from "../../components/aboutme/social_media/SocialMedia"
import { socialLinks } from "../../components/data";
import {sendPanelName} from "../../utils"
export default function Home({locationData}) {

  useEffect(()=>{
    if (locationData.country !== "")
    {sendPanelName(locationData, "Home")}
  },[])

  return (
    
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={8} className="home-header">
              <h1
                style={{ paddingBottom: 15, fontSize: "175px" }}
                className="heading"
              >
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> a Game Programmer </strong>
              </h1>

              <div className="typing-homeclass">
                <Typing />
              </div>
              <div className="sociallink-home">
                <ul className="homeaboutsociallinks">
                  
                  {
                    socialLinks.map((social, i)=>(
                      locationData.isRestricted || locationData.isvpn ? (
                        null
                      ) : (
                          <li className="socialicons" key = {i+"_social"}>
                            <a
                              href={social.url}
                              target="_blank"
                              rel="noreferrer"
                              className="iconcolour  homesocialicons"
                            >
                              {social.icon}
                            </a>
                          </li>
                      )
                    ))
                  }
                </ul>
              </div>
            </Col>
            <Col md={4} style={{ paddingBottom: 20 }}>
              <div className="img-home-main">
                <Tilt>
                  <Fade cascade>
                    <img style={{borderRadius:"100%"}}
                      src="./profile.jpg"
                      alt="Derek Miller - Senior Game Programmer specializing in Unity, Unreal, and Godot"
                      className="img-fluid"
                    />
                  </Fade>
                </Tilt>
              </div>
            </Col>
          </Row>
        {locationData.isRestricted || locationData.isvpn ? (
          null
        ) : (<>
          <Row>
            <Col md={2}>
              <div className="img-home-main">
                <Tilt>
                  <Fade cascade>
                    <img style={{borderRadius:"100%"}}
                      src="./UnityLogo.png"
                      alt="Unity Game Programmer"
                      className="img-fluid"
                    />
                  </Fade>
                </Tilt>
              </div>
            </Col>
            <Col md={2}>
              <div className="img-home-main">
                <Tilt>
                  <Fade cascade>
                    <img style={{borderRadius:"100%"}}
                      src="./Godot_icon.png"
                      alt="Godot Game Programmer"
                      className="img-fluid"
                    />
                  </Fade>
                </Tilt>
              </div>
            </Col>
            <Col md={2}>
              <div className="img-home-main">
                <Tilt>
                  <Fade cascade>
                    <img style={{borderRadius:"100%"}}
                      src="./UnrealLogo.jpeg"
                      alt="Unreal Game Programmer"
                      className="img-fluid"
                    />
                  </Fade>
                </Tilt>
              </div>
            </Col>
            <Col md={2}>
              <div className="img-home-main">
                <Tilt>
                  <Fade cascade>
                    <img style={{borderRadius:"100%"}}
                      src="./gamemaker.png"
                      alt="gamemaker Game Programmer"
                      className="img-fluid"
                    />
                  </Fade>
                </Tilt>
              </div>
            </Col>
          </Row>
          </>)}
        </Container>
      </Container>
      <AboutmeContainer />
      {
        locationData.isRestricted || locationData.isvpn ? (
          null
        ) : (
          <>
            <Projects locationData = {locationData}/>
            <Certification />
            <SocialMedia />
            {/* <Testimonial /> */}
          </>
        )
      }
      
      
      <Contactus locationData = {locationData}/>
    </section>
  );
}
