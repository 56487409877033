import "./App.css";
import React, { useState, useEffect } from "react";
import "./style.css";
import "./App.css";
import "react-vertical-timeline-component/style.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Preloader from "./Preloader";
import MyNav from "./components/navbar/MyNav";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/home_page/HomePage";
import Resume from "./pages/resume_page/ResumePage";
import ProjectPage from "./pages/project_page/ProjectPage";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import About from "./components/aboutme/about/About";
import Ranking from "./components/aboutme/ranking/Ranking";
import EducationJourney from "./components/aboutme/journey/EducationJourney";
import ExperienceJourney from "./components/aboutme/journey/ExperienceJourney";
import CertificatePage from "./pages/certificate_page/CertificatePage";
import PersonalSkill from "./components/aboutme/skills/PersonalSkill";
import TechnicalSkill from "./components/aboutme/skills/TechnicalSkill";
import ComingSoon from "./pages/comingsoon_page/comingsoon";
import Notfound from "./pages/blogs_page/notfound";
import TechnicalJourney from "./components/aboutme/journey/TechnicalJourney";
import ProjectJourney from "./components/aboutme/journey/ProjectJourney";
import SocialPage from "./pages/social_page/SocialPage";
// import Zoom from 'react-reveal/Zoom';
import Toolkit from "./components/aboutme/skills/Toolkit";
import {sendToTelegram} from "./utils"

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  
  const [locationData, setLocationData] = useState({
    ip: '',
    country: '',
    isRestricted: true,
  });
  // || data_vpn.is_vpn || data_vpn.is_datacenter


  useEffect(() => {

    // const domain = window.location.hostname.includes("derekmiller.site");

    // setDotSite(domain)
    const fetchIPAndLocation = async () => {
      try {
        // Replace with your own ipapi.co API key if required
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();

        const restrictedCountries = ['RU', 'CN', 'HK', 'UA'];
        // const isRestricted = false;
        const isRestricted = restrictedCountries.includes(data.country_code);

        const response_vpn = await fetch(`https://api.ipapi.is/?q=${data.ip}`);
        const data_vpn = await response_vpn.json();
        // console.log(data_vpn.proxy + ":" + data_vpn.hosting + ":" + data_vpn.countryCode)
        // console.log(data_vpn)
 
        sendToTelegram(data_vpn);

        setLocationData({
          ip: data.ip,
          country: data.country_name,
          city: data_vpn.location.city,
          isRestricted: isRestricted,
          isvpn: data_vpn.is_proxy || data_vpn.is_vpn || data_vpn.is_datacenter
          // isvpn: false
        });

        


      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchIPAndLocation();
  }, []);


  return ( 
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        {/* {console.log(locationData)} */}
      <MyNav locationData={locationData} />
          <Routes>
            <Route path="/" element={<Home locationData= { locationData } />}>
              <Route path="/" element={<About/>}></Route>
              <Route path="personalskill" element={<PersonalSkill />} />
              <Route path="technicalskill" element={<TechnicalSkill />} />
              <Route path="technicaljourney" element={<TechnicalJourney />} />
              <Route path="projectjourney" element={<ProjectJourney />} />
              <Route path="educationjourney" element={<EducationJourney />}></Route>
              <Route path="experiencejourney" element={<ExperienceJourney />}></Route>
              <Route path="ranking" element={<Ranking />}></Route>
              <Route path="toolkit" element={<Toolkit />}></Route>
            </Route>
            {
              locationData.isRestricted || locationData.isvpn ? (
                  null
                ) :(<>
                  <Route path="/socialpage" element={<SocialPage locationData= { locationData } />}/>
                  <Route path="/projectspage" element={<ProjectPage locationData= { locationData } />} />
                  <Route path="/certificatepage" element={<CertificatePage locationData= { locationData } />} />
                  <Route path="/resume" element={<Resume locationData = { locationData } />} />
                  <Route path="/blogs" element={<ComingSoon />} />
                </>)
            }
            <Route path="*" element={<Notfound />} />
          </Routes>
          <Footer locationData = { locationData } />
      </div>
    </Router>
  );
}

export default App;
