

import nyc3 from "../images/carRacing.png";
import nyc4 from "../images/rebelcars.PNG";
import nyc9 from "../images/PirateMetaverse.png";
import nyc11 from "../images/ICICB_Metaverse.png";
import nyc12 from "../images/eth_sniper.png";
import nyc15 from "../images/cryptoPocker.png";
import nyc17 from "../images/JackPot.png";
import nyc18 from "../images/BattleRoyal.png";
import nyc19 from "../images/FrozenSlot.png";
import nyc20 from "../images/MSFighter.png";
import nyc21 from "../images/MyFarm.png";
import nyc22 from "../images/OnlineFootBall.png";
import nyc24 from "../images/Bus&Subway.png";
import nyc25 from "../images/FruitMatch.png";
import nyc26 from "../images/NFT_Museum.png";
import nyc27 from "../images/DEEPSPACE.jpeg";
import nyc28 from "../images/carrieverse-Ofi_XDv2kJBs.jpg";
import nyc29 from "../images/1_nV0FnRp3p6cisgkQNrUaDg.webp";
import nyc30 from "../images/images.jfif";
import nyc33 from "../images/peacedeath.jpg";

import {
  AiFillGithub,
} from "react-icons/ai";
import { SiFiverr } from "react-icons/si";
import { FaGoogle,FaTwitter,FaSkype,
      FaTelegram,FaHackerrank,FaWhatsapp,
      FaMicrosoft,FaFreeCodeCamp, FaStackOverflow,
      FaYoutube} from "react-icons/fa";
import { SiCodechef, SiUdemy, SiFreelancer, SiItchdotio} from "react-icons/si";
import { FiAtSign } from "react-icons/fi";
import { FaDiscord } from "react-icons/fa6";
import { PiButterflyBold } from "react-icons/pi";

export const username = "Derek Miller"
export const userrole = "Game Programmer"

export default [
    {
      title:"Deepspace",
      img:nyc27,
      link:"https://deepspace.game",
      skills:"Ethereum, Unity, Photon",
      description:`Unity, Photon, Nethereum, Firebase`
      
    },
    {
      title:"District53",
      img:nyc29,
      link:"https://district53.io",
      skills:"Minetest, Lua, SXP",
      description:`Minetest, Lua, SXP`
      
    },
    {
      title:"Carrieverse",
      img:nyc28,
      link:"https://www.carrieverse.com",
      skills:"Ethereum, Unity, PlayFab",
      description:`Unity, PlayFab, Hive`
      
    },
    {
      title:"Divinity: Original Sin 2",
      img:nyc30,
      link:"https://divinity.game/",
      skills:"Divinity Engine",
      description:`Divinity Engine`
      
    },
    {
      title:"Eth Sniper",
      img:nyc12,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA    ",
      skills:"Ethereum, Unity, Photon",
      description:`Unity, Colyseus, Thirdweb, Firebase`
      
    },
    {
      title:"RebelCars",
      img:nyc4,
      link:"https://rebelcars.io",
      skills:"Solana",
      description:`Unreal Engine 5, Elysium`
    },
    // {
    //   title:"Psyber X",
    //   img:nyc32,
    //   link:"https://steamdb.info/app/2259710/",
    //   skills:"Unreal Engine 5",
    //   description:`Unreal Engine 5`
      
    // },
    {
      title:"ICICB Metaverse",
      img:nyc11,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA    ",
      skills:"Ethereum, Metaverse, Unity, Firebase",
      description:`Ethereum, Unity, Metaverse, ThirdWeb, Photon, Firebase`
      
    },
    {
      title:"PirateMetaverse",
      img:nyc9,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA    ",
      skills:"Solana, Metaverse, Unity",
      description:`Unity, Photon, Firebase, Solana, Metaverse`
      
    },
    {
      title:"Peace Death",
      img:nyc33,
      link:"https://store.steampowered.com/app/1546790/Peace_Death_2",
      skills:"Solana, Metaverse, Unity",
      description:`Godot`
      
    },
    {
      title:"CarRacing",
      img:nyc3,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"React, Node.js",
      description:`Unity`
      
    },
    {
      title:"FrozenSlot",
      img:nyc19,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    },
    {
      title:"FootBall",
      img:nyc22,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    },
    {
      title:"Poker",
      img:nyc15,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"y",
      description:`Unity`
    },
    {
      title:"Bus&Subway",
      img:nyc24,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    },
    {
      title:"NFTMuseum",
      img:nyc26,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity, Nethereum, NFT, Ethereum, Firebase,Photon`
      
    },
    {
      title:"FruitMatch",
      img:nyc25,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    },
    {
      title:"MyFarm",
      img:nyc21,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity, Firebase`
      
    },
    {
      title:"MSFighter",
      img:nyc20,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity,Firebase, Photon`
      
    },
    {
      title:"BattleRoyal",
      img:nyc18,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    },
    {
      title:"JackPot",
      img:nyc17,
      link:"https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA",
      skills:"Ethereum, Metaverse, Unity",
      description:`Unity`
      
    }
]


export const personalDetails = [
  {
    "platform": "Email",
    "icon": <FiAtSign/>,
    "contact": "derek.miller03@outlook.com",
  },
  {
    "platform": "Email",
    "icon": <FaGoogle/>,
    "contact": "derek.miller0003@gmail.com",
  },
  {
    "platform": "Skype",
    "icon": <FaSkype/>,
    "contact": "live:.cid.73d9933f3c8fe3e3",
  },
  {
    "platform": "Discord",
    "icon": <FaDiscord/>,
    "contact": "derekgamedev",
  },
  {
    "platform": "Whatsapp",
    "icon": <FaWhatsapp/>,
    "contact": "+49 176 68391022",
  },
  {
    "platform": "Telegram",
    "icon": <FaTelegram/>,
    "contact": "@DerekGameDev",
  }
]

export const socialLinks = [
    {
      "platform": "GitHub",
      "url": "https://github.com/SirBang",
      "icon": <AiFillGithub/>,
      "color": "white"
    },
    {
      "platform": "X",
      "url": "https://x.com/DerekMillerDev",
      "icon": <FaTwitter/>,
      "color": "white"
    },
    {
      "platform": "StackOverflow",
      "url": "https://stackoverflow.com/users/2827351/derek-miller",
      "icon": <FaStackOverflow/>,
      "color": "white"
    },
    {
      "platform": "Youtube",
      "url": "https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA/",
      "icon": <FaYoutube/>,
      "color": "white"
    },
    {
      "platform": "Telegram",
      "url": "https://t.me/DerekGameDev",
      "icon": <FaTelegram/>,
      "color": "white"
    },
    {
      "platform": "Discord",
      "url": "https://discord.gg/2XkCp5fKKH",
      "icon": <FaDiscord/>,
      "color": "white"
    },
    {
      "platform": "Skype",
      "url": "https://join.skype.com/invite/wZhvZs98jgUV",
      "icon": <FaSkype/>,
      "color": "white"
    },
    {
      "platform": "Whatsapp",
      "url": "https://wa.me/+4917668391022",
      "icon": <FaWhatsapp/>,
      "color": "white"
    },
    {
      "platform": "Email",
      "url": "mailto:derek.miller0003@gmail.com/",
      "icon": <FaGoogle/>,
      "color": "white"
    }
  ]

  export const socialmedias = [
    {
      "platform": "GitHub",
      "url": "https://github.com/SirBang",
      "icon": <AiFillGithub/>,
      "color": "white"
    },
    {
      "platform": "X",
      "url": "https://x.com/DerekMillerDev",
      "icon": <FaTwitter/>,
      "color": "white"
    },
    {
      "platform": "StackOverflow",
      "url": "https://stackoverflow.com/users/2827351/derek-miller",
      "icon": <FaStackOverflow/>,
      "color": "white"
    },
    {
      "platform": "Itch.io",
      "url": "https://derek-miller.itch.io/",
      "icon": <SiItchdotio/>,
      "color": "white"
    },
    {
      "platform": "Bluesky",
      "url": "https://bsky.app/profile/derekgamedev03.bsky.social",
      "icon": <PiButterflyBold />,
      "color": "white"
    },
    {
      "platform": "Youtube",
      "url": "https://www.youtube.com/channel/UCuxqgM5OjXTPyp6FUPSMGcA/",
      "icon": <FaYoutube/>,
      "color": "white"
    },
    {
      "platform": "HackerRank",
      "url": "https://www.hackerrank.com/profile/derek_miller03",
      "icon": <FaHackerrank/>,
      "color": "white"
    },
    {
      "platform": "Fiverr",
      "url": "https://www.fiverr.com/derekmiller03",
      "icon": <SiFiverr />,
      "color": "white"
    },
    {
      "platform": "Guru",
      "url": "https://www.guru.com/freelancers/derekgamedev",
      "icon": <SiFreelancer />,
      "color": "white"
    },
    {
      "platform": "Microsoft",
      "url": "https://learn.microsoft.com/en-us/users/derekgamedev/",
      "icon": <FaMicrosoft/>,
      "color": "white"
    },
    {
      "platform": "Freecodecamp",
      "url": "https://www.freecodecamp.org/derekgamedev",
      "icon": <FaFreeCodeCamp/>,
      "color": "white"
    },
    {
      "platform": "CodeChief",
      "url": "https://www.codechef.com/users/derekmiller",
      "icon": <SiCodechef/>,
      "color": "white"
    },
    {
      "platform": "Udemy",
      "url": "https://www.udemy.com/user/derek-miller-108/",
      "icon": <SiUdemy/>,
      "color": "white"
    },
  ]


export const certificates = [
  {
    link:"https://freecodecamp.org/certification/derekgamedev/foundational-c-sharp-with-microsoft",
    title:"C# Microsoft Certificate",
    platform:"FreeCodeCamp"
  },
  {
    link:"https://www.hackerrank.com/certificates/9cf297adc459?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"C# Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/ca23cbb1c9c6?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"Java Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/ba4902cba288",
    title:"Software Engineer",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/05ca6bfd65d0",
    title:"Javascript Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/0e2438c46e9c?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"Python Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/331cb1b9871f",
    title:"SQL Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/7786cb327c98?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"Nodejs Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/893be5ef1d74?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"REST API Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/7c3ae76ae8e0?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"Angular Certificate",
    platform:"HackerRank"
  },
  {
    link:"https://www.hackerrank.com/certificates/a0567ff4c755?utm_medium=email&utm_source=mail_template_1393&utm_campaign=hrc_skills_certificate",
    title:"React Certificate",
    platform:"HackerRank"
  },
]

export const testimonials = [
  {
    title:"CEO",
    photo:"photo_2022-02-07_00-25-56.jpg ",
    link:"https://x.com/WillemsTRA",
    review:"Derek is an excellent developer. I invited him to join the District53 metaverse project, where he was introduced to a completely new engine and language. Despite these challenges, he quickly learned and made significant progress from the first week. I am deeply impressed by his passion and attitude towards his work. His dedication and spirit have been an inspiration to everyone in our company."
    
  },
  {
    title:"Lead Game Developer",
    photo:"photo_2024-09-16_01-55-20.jpg",
    link:"https://www.linkedin.com/in/sami-mattila/",
    review:"Derek possesses robust experience in game development and software engineering. His approach to work and outstanding code quality are golden assets. He brings extensive experience with various gameplay systems, providing significant support to our projects. Additionally, Derek is proficient in multiple skills beyond game development, including Node.js, MySQL, web development, and React, excelling in these areas as though he were a seasoned professional."
  },
  {
    title:"CMO",
    photo:"photo_2024-03-14_22-25-13.jpg",
    link:"https://linkedin.com/in/jacekstudzinski",
    review:"I enjoy discussing the project with Derek. He is always thinking deeply about his work and provides invaluable ideas for the project and its strategy. When he shares his vision for the project, his level of commitment and responsibility is so significant that he could easily be mistaken for the project owner."
  }
]


